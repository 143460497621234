import "./pages.scss";
import { useEffect, useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { DeleteOutlineOutlined, VisibilityOutlined } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Collapse, Modal, Stack, Typography } from "@mui/material";

import AddPage from "../addPage/AddPage";
import { Link } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: "0.5px solid #000",
    boxShadow: 24,
    p: 3,
};

const innerForDelete = ({ handleDelete, setOpenModal, id }) => {
    return (
        <div className="modalButton">
            <Button
                size="small"
                variant="contained"
                className="deleteYes"
                onClick={(e) => {
                    setOpenModal(false);
                    handleDelete(id);
                }}
            >
                YES
            </Button>
            <Button
                size="small"
                variant="contained"
                color="error"
                className="deleteNo"
                onClick={(e) => setOpenModal(false)}
            >
                NO
            </Button>
        </div>
    );
};

const pageColumns = [
    {
        field: "number",
        headerName: "№",
    },

    {
        field: "pagePath",
        headerName: "Path for page",
        width: 400,
    },
];

const Pages = () => {
    const { user } = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [columns, setColumns] = useState([]);
    const [checkedCollapse, setCheckedCollapse] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalInner, setModalInner] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const [data, setData] = useState([]);
    useEffect(() => {
        axios.defaults.headers.common["x-access-token"] = user
            ? user.accessToken
            : null;
    }, [user]);
    useEffect(() => {
        setColumns(pageColumns);
        let list = [];
        const fetchdata = async () => {
            try {
                // get pages
                const resDest = await axios.get("/api/page");
                resDest.data.forEach((doc, i) => {
                    list.push({ id: doc._id, number: i + 1, ...doc });
                });
                setData(list);
            } catch (err) {
                console.log(err);
            }
        };

        fetchdata();
    }, []);
    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,

            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <Link
                            to={`/editpage/${params.row.id}`}
                            style={{ textDecoration: "none" }}
                        >
                            <VisibilityOutlined className="icon" />
                        </Link>

                        <DeleteOutlineOutlined
                            onClick={(e) =>
                                handleOpenModal("delete", params.row.id)
                            }
                            className="icon"
                            style={{ color: "red" }}
                        />
                    </div>
                );
            },
        },
    ];
    const handleChangeCollapse = () => {
        setCheckedCollapse((prev) => !prev);
    };
    const addPage = async (newPage) => {
        try {
            const res = await axios.post("/api/page", newPage);
            const newItem = { id: res.data._id, ...res.data };
            newItem.number = data.length + 1;
            setData((prev) => [...prev, newItem]);
        } catch (err) {
            console.log(err);
            let errorMessage =
                err && err.response && err.response.data
                    ? err.response.data.message
                    : "Request failed with status code 500";
            setError(errorMessage);
            setTimeout(() => {
                setError("");
            }, 7000);
        }
    };
    const handleDelete = async (id) => {
        try {
            await axios.delete(`/api/page/${id}`);
            let pages = data.filter((item) => item.id !== id);
            pages.forEach((item, i) => (item.number = i + 1));
            setData(pages);
        } catch (err) {
            console.log(err);
            let errorMessage =
                err && err.response && err.response.data
                    ? err.response.data.message
                    : "Request failed with status code 500";
            setError(errorMessage);
            setTimeout(() => {
                setError("");
            }, 7000);
        }
    };

    const handleOpenModal = (action, data) => {
        if (action && action === "delete") {
            setModalTitle("Delete?");
            setModalInner(
                innerForDelete({ handleDelete, setOpenModal, id: data })
            );
            setOpenModal(true);
        }
    };
    const handleCloseModal = () => setOpenModal(false);
    return (
        <div className="pages">
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {modalTitle}
                    </Typography>
                    <div id="modal-modal-description">{modalInner}</div>
                </Box>
            </Modal>
            <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                <Button
                    variant={checkedCollapse ? "contained" : "outlined"}
                    onClick={handleChangeCollapse}
                >
                    New Page
                </Button>
            </Stack>
            <div className="addWrap">
                <Collapse in={checkedCollapse}>
                    <AddPage addPage={addPage} />
                </Collapse>
            </div>

            {error && (
                <Typography component="p" color="red">
                    {error}
                </Typography>
            )}
            <DataGrid
                className="datagrid"
                rows={data}
                columns={columns.concat(actionColumn)}
                pageSize={100}
                rowsPerPageOptions={[100]}
                disableSelectionOnClick
                autoHeight={true}
                hideFooter={true}
                hideFooterPagination={true}
            />
        </div>
    );
};

export default Pages;
