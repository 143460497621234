import {
    DeleteOutlineOutlined,
    KeyboardArrowDown,
    KeyboardArrowUp,
} from "@mui/icons-material";
import { Button, Stack, TextField } from "@mui/material";
import Collapse from "@mui/material/Collapse";
//import axios from "axios";

import { useState } from "react";
import "./block.scss";

const Block = ({
    saveBlock,
    deleteBlock,
    initData,
    index,
    handleAddBlocOnTop,
}) => {
    const [data, setData] = useState(initData);
    const [checkedCollapse, setCheckedCollapse] = useState(false);
    //const [file, setImage] = useState("");
    const [actions] = useState([
        "title",
        "title1",
        "title2",
        "text",
        "text1",
        "text2",
        "image",
        "video",
        "link",
        "notice",
        "header",

        "address",
        "tel",
        "place",
        "button",
    ]);

    // const fileUpload = async (e) => {
    //     console.log(e);
    //     e.preventDefault();
    //     if (file) {
    //         const dataForSend = new FormData();
    //         const fileName = file.name;
    //         dataForSend.append("name", fileName);
    //         dataForSend.append("file", file);

    //         try {
    //             const fullFileName = "/" + fileName;
    //             data.image = fullFileName;
    //             console.log(data);
    //             setData({ ...data });
    //             saveBlock(data);
    //             setImage("");
    //             console.log(file);
    //             await axios.post("/api/upload", dataForSend);
    //         } catch (err) {
    //             console.log(err);
    //         }
    //     }
    // };

    const handleChangeCollapse = () => {
        setCheckedCollapse((prev) => !prev);
    };
    const handleSave = (e) => {
        e.preventDefault();
        saveBlock(data);
        setCheckedCollapse(false);
    };
    const handleDelete = (e) => {
        e.preventDefault();
        deleteBlock(data._id);
    };
    const handleAddField = (e, action) => {
        e.preventDefault();
        if (typeof data[action] === "undefined") {
            data[action] = "";
            setData({ ...data });
        }
        if (!checkedCollapse) {
            setCheckedCollapse(true);
        }
    };
    const handleInput = (e) => {
        e.preventDefault();
        const id = e.target.id;
        const value = e.target.value;
        setData({ ...data, [id]: value });
    };
    const deleteAction = (action) => {
        delete data[action];
        console.log(data);
        setData({ ...data });
    };

    const AddBlocOnTop = (e) => {
        e.preventDefault();
        handleAddBlocOnTop(index - 1);
    };
    return (
        <div className="block">
            <div className="buttons">
                <div className="actions">
                    <span className="blockIndex">{index}</span>
                    {actions.map((a) => {
                        return (
                            <span
                                key={a}
                                className={data[a] ? "action active" : "action"}
                                onClick={(e) => {
                                    handleAddField(e, a);
                                }}
                            >
                                {a}
                            </span>
                        );
                    })}
                </div>
                <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                    <Button
                        variant="outlined"
                        size="small"
                        type="button"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        type="button"
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        type="button"
                        onClick={AddBlocOnTop}
                    >
                        Add block on top
                    </Button>
                </Stack>

                <div className="iconWrapper">
                    {checkedCollapse && (
                        <KeyboardArrowUp
                            onClick={handleChangeCollapse}
                            className="collapseIcon"
                        />
                    )}
                    {!checkedCollapse && (
                        <KeyboardArrowDown
                            onClick={handleChangeCollapse}
                            className="collapseIcon"
                        />
                    )}
                </div>
            </div>
            <Collapse in={checkedCollapse}>
                <div className="innerBlock">
                    {actions
                        .filter((a) => typeof data[a] !== "undefined")
                        .map((a, i) => {
                            return (
                                <div className="inputGroup" key={i}>
                                    <TextField
                                        sx={{ margin: 0.5 }}
                                        size="small"
                                        fullWidth={true}
                                        value={data[a]}
                                        onChange={handleInput}
                                        id={a}
                                        label={a}
                                    />

                                    <div className="deleteIcon">
                                        <DeleteOutlineOutlined
                                            onClick={(e) => {
                                                e.preventDefault();
                                                deleteAction(a);
                                            }}
                                            className="icon"
                                            style={{ color: "red" }}
                                        />
                                    </div>
                                </div>
                            );
                        })}

                    {/* {typeof data["image"] !== "undefined" && (
                    <div className="inputGroup">
                        <div className="uploadImage">
                            <img
                                src={
                                    file
                                        ? URL.createObjectURL(file)
                                        : data.image
                                        ? PF + data.image
                                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                                }
                                alt=""
                            />
                            <label htmlFor="file">
                                <DriveFolderUploadOutlined className="icon" />
                            </label>
                            <input
                                type="file"
                                id="file"
                                onChange={(e) => setImage(e.target.files[0])}
                                style={{ display: "none" }}
                            />
                            {file && (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    type="button"
                                    onClick={(e) => {
                                        fileUpload(e);
                                    }}
                                >
                                    Upload
                                </Button>
                            )}
                        </div>
                        <div className="deleteIcon">
                            <DeleteOutlineOutlined
                                onClick={(e) => {
                                    e.preventDefault();
                                    deleteAction("image");
                                }}
                                className="icon"
                                style={{ color: "red" }}
                            />
                        </div>
                    </div>
                )} */}
                </div>
            </Collapse>
        </div>
    );
};

export default Block;
