import {
    AddBox,
    DeleteOutlineOutlined,
    KeyboardArrowDown,
    KeyboardArrowUp,
} from "@mui/icons-material";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Stack,
    TextField,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
//import axios from "axios";

import { useState } from "react";
import "./block.scss";

const Block = ({ saveBlock, deleteBlock, initData, index }) => {
    //console.log(initData);
    const [data, setData] = useState(initData);
    const [checkedCollapse, setCheckedCollapse] = useState(false);
    //const [file, setImage] = useState("");
    const [actions] = useState(["th+", "td+"]);

    // const fileUpload = async (e) => {
    //     console.log(e);
    //     e.preventDefault();
    //     if (file) {
    //         const dataForSend = new FormData();
    //         const fileName = file.name;
    //         dataForSend.append("name", fileName);
    //         dataForSend.append("file", file);

    //         try {
    //             const fullFileName = "/" + fileName;
    //             data.image = fullFileName;
    //             console.log(data);
    //             setData({ ...data });
    //             saveBlock(data);
    //             setImage("");
    //             console.log(file);
    //             await axios.post("/api/upload", dataForSend);
    //         } catch (err) {
    //             console.log(err);
    //         }
    //     }
    // };

    const handleChangeCollapse = () => {
        setCheckedCollapse((prev) => !prev);
    };
    const handleSave = (e) => {
        e.preventDefault();
        saveBlock(data);
        setCheckedCollapse(false);
    };
    const handleDelete = (e) => {
        e.preventDefault();
        deleteBlock(data._id);
    };
    const handleAddField = (e, action) => {
        e.preventDefault();
        data.tdData.push([""]);
        setData({ ...data });
        if (!checkedCollapse) {
            setCheckedCollapse(true);
        }
    };
    const handleInput = (e) => {
        e.preventDefault();
        const id = e.target.id;
        const value = e.target.value;
        setData({ ...data, [id]: value });
    };
    const handleInputTD = (e, i, j) => {
        e.preventDefault();
        data.tdData[i][j] = e.target.value;
        console.log(i, j, e.target.value);

        setData({ ...data });
    };
    const addParagrahInTD = (e, i) => {
        e.preventDefault();
        data.tdData[i].push("");
        console.log(i, data.tdData[i]);

        setData({ ...data });
    };
    const handleCheck = async (e) => {
        const field = e.target.id;
        const value = e.target.checked;
        const field2 = field === "th" ? "td" : "th";
        const value2 = !e.target.checked;

        setData({ ...data, [field]: value, [field2]: value2 });
    };
    const deleteAction = (i) => {
        data.tdData.splice(i, 1);
        console.log(data);
        setData({ ...data });
    };
    return (
        <div className="block">
            <div className="buttons">
                <div className="actions">
                    <span className="blockIndex">{index}</span>
                    {actions.map((a) => {
                        return (
                            ((data.th && a === "th+") ||
                                (data.td && a === "td+")) && (
                                <span
                                    key={a}
                                    className="action"
                                    onClick={(e) => {
                                        handleAddField(e, a);
                                    }}
                                >
                                    {a}
                                </span>
                            )
                        );
                    })}
                    {data && typeof data.th !== "undefined" && (
                        <FormControlLabel
                            className="checkTdTh"
                            label="th"
                            control={
                                <Checkbox
                                    checked={data.th}
                                    onChange={handleCheck}
                                    id="th"
                                    inputProps={{
                                        "aria-label": "controlled",
                                    }}
                                />
                            }
                        />
                    )}
                    {data && typeof data.td !== "undefined" && (
                        <FormControlLabel
                            label="td"
                            control={
                                <Checkbox
                                    checked={data.td}
                                    onChange={handleCheck}
                                    id="td"
                                    inputProps={{
                                        "aria-label": "controlled",
                                    }}
                                />
                            }
                        />
                    )}
                    <TextField
                        sx={{ margin: 0.5, width: 100 }}
                        size="small"
                        type="number"
                        value={data.colSpan}
                        onChange={handleInput}
                        id="colSpan"
                        label="col span"
                    />
                </div>
                <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                    <Button
                        variant="outlined"
                        size="small"
                        type="button"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        type="button"
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                </Stack>

                <div className="iconWrapper">
                    {checkedCollapse && (
                        <KeyboardArrowUp
                            onClick={handleChangeCollapse}
                            className="collapseIcon"
                        />
                    )}
                    {!checkedCollapse && (
                        <KeyboardArrowDown
                            onClick={handleChangeCollapse}
                            className="collapseIcon"
                        />
                    )}
                </div>
            </div>
            <Collapse in={checkedCollapse}>
                <div className="innerBlock">
                    {data.tdData.map((td, i) => {
                        return (
                            <div className="inputGroup" key={i}>
                                {td.map((p, j) => {
                                    return (
                                        <TextField
                                            sx={{ margin: 0.5 }}
                                            size="small"
                                            fullWidth={true}
                                            value={data.tdData[i][j]}
                                            onChange={(e) =>
                                                handleInputTD(e, i, j)
                                            }
                                        />
                                    );
                                })}

                                <div className="addIcon">
                                    <AddBox
                                        onClick={(e) => {
                                            addParagrahInTD(e, i);
                                        }}
                                        className="icon"
                                        style={{ color: "green" }}
                                    />
                                </div>
                                <div className="deleteIcon">
                                    <DeleteOutlineOutlined
                                        onClick={(e) => {
                                            e.preventDefault();
                                            deleteAction(i);
                                        }}
                                        className="icon"
                                        style={{ color: "red" }}
                                    />
                                </div>
                            </div>
                        );
                    })}

                    {/* {typeof data["image"] !== "undefined" && (
                    <div className="inputGroup">
                        <div className="uploadImage">
                            <img
                                src={
                                    file
                                        ? URL.createObjectURL(file)
                                        : data.image
                                        ? PF + data.image
                                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                                }
                                alt=""
                            />
                            <label htmlFor="file">
                                <DriveFolderUploadOutlined className="icon" />
                            </label>
                            <input
                                type="file"
                                id="file"
                                onChange={(e) => setImage(e.target.files[0])}
                                style={{ display: "none" }}
                            />
                            {file && (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    type="button"
                                    onClick={(e) => {
                                        fileUpload(e);
                                    }}
                                >
                                    Upload
                                </Button>
                            )}
                        </div>
                        <div className="deleteIcon">
                            <DeleteOutlineOutlined
                                onClick={(e) => {
                                    e.preventDefault();
                                    deleteAction("image");
                                }}
                                className="icon"
                                style={{ color: "red" }}
                            />
                        </div>
                    </div>
                )} */}
                </div>
            </Collapse>
        </div>
    );
};

export default Block;
