import "./home.scss";
import Navbar from "../../components/navbar/Navbar";
import Pages from "../../components/pages/Pages";

const Home = () => {
    return (
        <div className="home">
            <div className="homeContainer">
                <Navbar />

                <div className="listContainer">
                    <Pages />
                </div>
            </div>
        </div>
    );
};

export default Home;
