import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import "./addPage.scss";

const AddPage = ({ addPage }) => {
    const initData = {
        pagePath: "",
    };
    const [fullWidth] = useState(true);
    const [data, setData] = useState(initData);
    const [isFetching, setIsFetching] = useState(false);

    const handleInput = (e) => {
        e.preventDefault();
        const id = e.target.id;
        const value = e.target.value;
        setData({ ...data, [id]: value });
    };

    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            setIsFetching(true);

            const newItem = {
                pagePath: data.pagePath,
                id: Date.now(),
            };
            await addPage(newItem);
            setData(initData);
            setIsFetching(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div className="addPage">
                <Box component="form" onSubmit={handleAdd}>
                    <div className="inputGroup">
                        <TextField
                            sx={{ margin: 0.5 }}
                            size="small"
                            fullWidth={fullWidth}
                            required
                            value={data.pagePath}
                            onChange={handleInput}
                            id="pagePath"
                            label="path for page"
                        />
                        <Button
                            sx={{ margin: 0.5 }}
                            variant="contained"
                            type="submit"
                            disabled={isFetching}
                        >
                            Add
                        </Button>
                    </div>
                </Box>
            </div>
        </>
    );
};

export default AddPage;
