import "./navbar.scss";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
const Navbar = () => {
    const { user, dispatch } = useContext(AuthContext);

    return (
        <div className="navbar">
            <div className="wrapper">
                <div className="logo">
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <span className="logo">RELIANCE INSURANCE Manager</span>
                    </Link>
                </div>
                <div className="items">
                    {user && (
                        <div className="item">
                            <li>
                                <span>{user.email}</span>
                            </li>
                        </div>
                    )}

                    <div className="item">
                        <div
                            onClick={() => dispatch({ type: "LOGOUT" })}
                            style={{ textDecoration: "none" }}
                        >
                            <li>
                                <PersonOutlineIcon className="icon" />
                                <span>Logout</span>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
