import "./editPage.scss";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState, useContext } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import Block from "../../components/block/Block";
import BlockTable from "../../components/block/BlockTable";

const EditPage = () => {
    const { user } = useContext(AuthContext);

    const [data, setData] = useState({ blocks: [] });
    const [success, setSuccess] = useState(false);
    let { id } = useParams();
    useEffect(() => {
        axios.defaults.headers.common["x-access-token"] = user
            ? user.accessToken
            : null;
    }, [user]);

    useEffect(() => {
        const fetchdata = async () => {
            try {
                const resDest = await axios.get("/api/page/" + id);
                if (!resDest.data.fileDestination) {
                    resDest.data.fileDestination = "/src";
                }
                if (typeof resDest.data.asArray === "undefined") {
                    resDest.data.asArray = true;
                }
                setData(resDest.data);
                // console.log(resDest.data);
            } catch (err) {
                console.log(err);
            }
        };
        fetchdata();
    }, [id]);

    const handleAddBlocOnTop = async (idx) => {
        data.blocks.splice(idx, 0, {});
        try {
            await axios.put("/api/page/" + id, {
                blocks: [...data.blocks],
            });
            const resDest = await axios.get("/api/page/" + id);
            if (!resDest.data.fileDestination) {
                resDest.data.fileDestination = "/src";
            }
            setData(resDest.data);
        } catch (err) {
            console.log(err);
        }
    };
    const handleAddBlock = async (e) => {
        e.preventDefault();
        // console.log("handleAddBlock");
        try {
            await axios.put("/api/page/" + id, {
                blocks: [...data.blocks, {}],
            });
            const resDest = await axios.get("/api/page/" + id);
            if (!resDest.data.fileDestination) {
                resDest.data.fileDestination = "/src";
            }
            setData(resDest.data);
        } catch (err) {
            console.log(err);
        }
    };
    const saveBlock = async (saveData) => {
        //console.log("handleSaveBlock", saveData);
        const block = data.blocks.find((b) => b._id === saveData._id);
        const keys = Object.keys(saveData);
        const keysOld = Object.keys(block);
        const keyForDelete = keysOld.filter((k) => keys.indexOf(k) < 0);
        keyForDelete.forEach((k) => delete block[k]);
        keys.forEach((k) => (block[k] = saveData[k]));

        setData((prev) => {
            prev.blocks = [...prev.blocks];
            return JSON.parse(JSON.stringify(prev));
        });
        //console.log(data.blocks);
        try {
            await axios.put("/api/page/" + id, {
                blocks: data.blocks,
            });
        } catch (err) {
            console.log(err);
        }
    };
    const deleteBlock = async (blockId) => {
        console.log("handleDeleteBlock", id);
        try {
            setData((prev) => {
                prev.blocks = prev.blocks.filter((b) => b._id !== blockId);
                return JSON.parse(JSON.stringify(prev));
            });
            const res = await axios.put("/api/page/" + id, {
                blocks: data.blocks,
            });

            console.log(res);
        } catch (err) {
            console.log(err);
        }
    };
    const handleInput = async (e) => {
        e.preventDefault();

        const field = e.target.id;
        const value = e.target.value;

        setData({ ...data, [field]: value });

        try {
            const res = await axios.put("/api/page/" + id, {
                [field]: value,
            });
            console.log(res);
        } catch (err) {
            console.log(err);
        }
    };
    const handleCheck = async (e) => {
        const field = e.target.id;
        const value = e.target.checked;
        setData({ ...data, [field]: value });
        try {
            const res = await axios.put("/api/page/" + id, { [field]: value });
            console.log(res);
        } catch (err) {
            console.log(err);
        }
    };
    const handleCreateJSON = async () => {
        try {
            await axios.get("/api/createJSON/" + id);

            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 2000);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="editPage">
            <div className="editPageContainer">
                <Navbar />

                <div className="editPageWrap">
                    <div className="editPageTitle">
                        {data && typeof data.pagePath !== "undefined" && (
                            <TextField
                                sx={{ margin: 0.5, width: 350 }}
                                variant="standard"
                                value={data.pagePath}
                                onChange={handleInput}
                                id="pagePath"
                                label="Page name"
                            />
                        )}
                    </div>
                    <div className="actionsWrap">
                        <Button
                            variant={"contained"}
                            onClick={handleAddBlock}
                            size="small"
                        >
                            New Block
                        </Button>
                        {data &&
                            typeof data.fileDestination !== "undefined" && (
                                <TextField
                                    sx={{ margin: 0.5, width: 350 }}
                                    size="small"
                                    value={data.fileDestination}
                                    onChange={handleInput}
                                    id="fileDestination"
                                    label="FileDestination"
                                />
                            )}

                        {data && typeof data.idInFile !== "undefined" && (
                            <TextField
                                sx={{ margin: 0.5, width: 250 }}
                                size="small"
                                value={data._id}
                                disabled="true"
                                id="idInFile"
                                label="id for fetch data"
                            />
                        )}

                        {data && typeof data.asArray !== "undefined" && (
                            <FormControlLabel
                                label="as array"
                                control={
                                    <Checkbox
                                        checked={data.asArray}
                                        onChange={handleCheck}
                                        id="asArray"
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                    />
                                }
                            />
                        )}

                        {data && typeof data.asTable !== "undefined" && (
                            <FormControlLabel
                                label="as table"
                                control={
                                    <Checkbox
                                        checked={data.asTable}
                                        onChange={handleCheck}
                                        id="asTable"
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                    />
                                }
                            />
                        )}

                        <Button
                            color={success ? "success" : "primary"}
                            variant={"contained"}
                            onClick={handleCreateJSON}
                            size="small"
                        >
                            Create JSON
                        </Button>
                    </div>
                    <div className="actionsWrap">
                        {data && typeof data.note !== "undefined" && (
                            <TextField
                                sx={{ margin: 0.5 }}
                                size="small"
                                fullWidth
                                value={data.note}
                                onChange={handleInput}
                                id="note"
                                variant="standard"
                                label="Comments"
                            />
                        )}
                    </div>

                    <div className="blocks">
                        {data &&
                            data.blocks &&
                            data.blocks.map((b, index) => {
                                return (
                                    (!data.asTable && (
                                        <Block
                                            key={b._id}
                                            saveBlock={saveBlock}
                                            deleteBlock={deleteBlock}
                                            handleAddBlocOnTop={
                                                handleAddBlocOnTop
                                            }
                                            initData={b}
                                            index={index + 1}
                                        />
                                    )) ||
                                    (data.asTable && (
                                        <BlockTable
                                            key={b._id}
                                            saveBlock={saveBlock}
                                            deleteBlock={deleteBlock}
                                            handleAddBlocOnTop={
                                                handleAddBlocOnTop
                                            }
                                            initData={b}
                                            index={index + 1}
                                        />
                                    ))
                                );
                            })}
                    </div>
                    <div className="actionWrap">
                        <Button
                            variant={"contained"}
                            sx={{ mt: "15px" }}
                            fullWidth
                            onClick={handleAddBlock}
                            size="small"
                        >
                            New Block
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditPage;
